import React from "react";

import "./Header.scss";

const Header = ({ children, id, color, compact, symbols, classes }) => {

  return (
    <header id={id} style={{ backgroundColor: color }} className={(compact ? '' : symbols ? 'has-symbols': '') + (classes ? classes : '')}>
      <div className="container">
        {children}
      </div>
    </header>
  )
}

export default Header;
